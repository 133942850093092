<template>
  <div v-if="profileCopied" class="profile-wrapper">
    <button class="save-button" @click="updateProfile">
      <i v-if="updatedProfileResult && !updatedProfileResult.loaded" class="fa fa-cog fa-spin spin-fix"></i>
      <i v-else class="fa fa-save"/>
    </button>
    <p-value-modal
      v-if="showValueModal"
      :choices="availableValueChoices"
      @close="showValueModal = false"
      @addvalue="addValue"
    />
    <p-statistic-modal
      v-if="showStatisticModal"
      :choices="availableStatisticChoices"
      @close="showStatisticModal = false"
      @addstatistic="addStatistic"
    />
    <p-social-modal
      v-if="showSocialModal"
      :choices="updatedProfile.socialMediaChoices"
      :links="updatedProfile.socialMediaLinks"
      @close="showSocialModal = false"
      @addsocial="addSocial"
    />
    <!-- HEADER section -->
    <div id="header" class="row header">
      <div class="header-background col-12">
        <h1 class="profile-heading">Profil von {{ updatedProfile.profile.name }}</h1>
        <p class="profile-preview-link">Das Profil erscheint auf der EntwicklerHeld Coding-Plattform unter folgendem
          Link:
          <a target="_blank" :href="'https://platform.entwicklerheld.de/company/'+companyProfileSlug">
            https://platform.entwicklerheld.de/company/{{ companyProfileSlug }}
          </a>
          <br/>
          Support: <a href="mailto:happycustomer@entwicklerheld.de?subject=EntwicklerHeld Company Hub Supportanfrage">happycustomer@entwicklerheld.de</a>
        </p>
      </div>
    </div>

    <!--LOGO section-->
    <div id="logo" class="row logo-icon section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-image section-icon"></i>
      </div>
      <div class="col-12 col-lg-6">
        <h2>
          Unternehmenslogo
        </h2>
        <p class="advice">
          Ihr <strong>Unternehmenslogo</strong> wird überall auf der Plattform <strong>präsent</strong> sein, sei es in
          der
          <strong>Unternehmensliste</strong> oder an für Sie <strong>gebrandeten Challenges</strong>. Achten Sie darauf,
          dass sie
          es als <strong>*.png-Datei</strong> mit transparentem Hintergrund hochladen.
        </p>
        <input
          style="height: 0; width: 0; padding:0; border: none;"
          class="selection"
          @focus="addSelectionClass"
          @blur="removeSelectionClass"
        />
        <div class="selected-image-wrapper">
          <div v-if="!updatedProfile.profile.logo" class="no-image-selected no-image-panel">
            <div class="no-image-border hvr-grow logo-upload-box">
              <input type="file" @change="loadLogo"/>
              <i class="fa fa-image"></i>
              <h4>Logo für Upload auswählen</h4>
              <h5>Oder Datei ziehen und hier ablegen</h5>
            </div>
          </div>
        </div>

        <div class="selected-image-wrapper">
          <div v-if="updatedProfile.profile.logo" class="selected-image-container image-panel">
            <img class="logo" :src="updatedProfile.profile.logo"/>
            <div class="remove-item remove-logo hvr-grow" @click="clearLogo">
              <i class="fa fa-minus remove-item-icon"/>
            </div>
          </div>

          <label v-if="updatedProfile.profile.logo" class="upload">
            Neues Logo hochladen
            <input type="file" @change="loadLogo"/>
          </label>
        </div>
      </div>
    </div>

    <!--SLOGAN section-->
    <div id="slogan" class="row slogan section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-quote-right section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <h2>
          Slogan
        </h2>
        <p class="advice">
          Ihr <strong>Slogan</strong> ist das Erste, was Entwickler:innen über Ihr Unternehmen in der
          <strong>Unternehmensdarstellung</strong> lesen. Er entscheidet darüber, ob Entwickler:innen
          <strong>mehr</strong> über Ihr Unternehmen <strong>erfahren</strong> möchten. Inhaltlich sollte er in
          <strong>zwei bis drei</strong> Sätzen erklären, inwieweit Ihr Unternehmen für die Zielgruppe spannend ist.
          Dabei ist wichtig, in welchem Kontext Sie <strong>Softwareentwicklung</strong> einsetzen. Halten Sie Ihren
          Slogan kurz und bündig!
        </p>
        <textarea-autosize
          v-model.trim="updatedProfile.profile.slogan"
          class="input-text selection"
          placeholder="Slogan"
          @focus.native="addSelectionClass"
          @blur.native="removeSelectionClass"
        />
      </div>
    </div>

    <!--DESCRIPTION section-->
    <div id="description" class="row slogan section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-file section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <h2>
          Beschreibung
        </h2>
        <p class="advice">
          In Ihrer <strong>Unternehmensbeschreibung</strong> können Sie detaillierter werden. Was macht Ihr Unternehmen
          aus und warum sollten sich Entwickler:innen für Ihr Unternehmen entscheiden? Zeigen Sie in einem kurzen Absatz
          alle <strong>Vorzüge</strong> Ihres Unternehmens auf. Beschreiben Sie, ob sie ein
          <strong>Produkt</strong> entwickeln oder <strong>Dienstleistungen</strong> anbieten. Spannend ist außerdem,
          wie der <strong>Alltag in der Dev-Abteilung</strong> bei Ihnen aussieht. Gehen Sie dabei auf die
          <strong>Art und Weise</strong> ein, wie sie Software entwickeln. Dabei gilt wie immer: <strong>So lang wie
          nötig, so kurz wie möglich.</strong>
        </p>
        <textarea-autosize
          v-model.trim="updatedProfile.profile.aboutUs"
          class="input-text selection"
          placeholder="Beschreibung"
          @focus.native="addSelectionClass"
          @blur.native="removeSelectionClass"
        />
      </div>
    </div>

    <!--LOCATION section-->
    <div id="location" class="row locations section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-map-marker section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="section-header">
          <h2>
            Standorte
          </h2>
          <p class="advice">
            Unsere Plattform ist <strong>regional</strong> basiert, weil ein neuer Job nicht immer am anderen Ende von
            Deutschland oder der Welt liegen muss. Tragen Sie hier die <strong>Standorte</strong> Ihres Unternehmens
            ein, an denen Sie präsent sind.
          </p>
        </div>
        <div class="location-wrapper">
          <div class="input-address-section">
            <VueGooglePlaces
              api-key="AIzaSyCVWu7dN6ToxD8ksp17hJ9DOtVkN19jbxY"
              types="address"
              @placechanged="onPlaceChanged"
            >
              <input
                v-model="enteredAddress"
                class="input-address selection"
                type="text"
                placeholder="Straße, Hausnummer, Ort"
                @focus="addSelectionClass"
                @blur="removeSelectionClass"
              />
            </VueGooglePlaces>
            <button
              class="add-location"
              :class="[this.enteredAddress === '' ? 'button-grey' : 'add-location-button']"
              @click="addLocation"
            >Hinzufügen
            </button>
          </div>
          <div class="added-locations">
            <div v-for="location in updatedProfile.locations" :key="location.address" class="location">
              <div class="white-box-text">
                <p>{{ location.address }}, {{ location.zipCode }} {{ location.city }}, {{ location.country }}</p>
                <div class="remove-item hvr-grow" @click="deleteLocation(location)">
                  <i class="fa fa-minus remove-item-icon"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CAREER PAGE section -->
    <div id="career-page" class=" row career-page section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-link section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <h2>Karriereseite</h2>
        <p class="advice">
          Verknüpfen Sie hier Ihre <strong>Karriereseite</strong>, auf der Sie <strong>aktive Interessent:innen
          anwerben</strong>
          sowie <strong>passiv wechselwillige Talente überzeugen</strong>,
          sich auf Ihre Stellenangebote zu melden, und Ihre <strong>Employer Branding Botschaft</strong> ansprechend
          vermitteln.
        </p>
        <div class="career-page-wrapper">
          <div class="input-career-page-section">
            <input
              v-model="enteredCareerPage"
              class="input-career-page"
              type="text"
              placeholder="https://my-career-page.com"
              @change="changeCareerPage"
              @focus="addSelectionClass"
              @blur="removeSelectionClass"
            />
          </div>
        </div>
      </div>
    </div>

    <!--REMOTE TYPE section -->
    <div id="remote-type" class="row remote-type section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-laptop section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <h2>Remote-Arbeit</h2>
        <p class="advice">
          Bitte geben Sie an, welche <strong>Möglichkeiten zur Remote-Arbeit (Home-Office)</strong> Ihr Unternehmen
          anbietet.
        </p>
        <div class="remote-type-wrapper">
          <div class="input-remote-type-section">
            <ul
              v-for="remoteType in updatedProfile.profile.remoteChoices"
              :key="remoteType.nameCode"
              class="list-group"
            >
              <li class="list-group-item">
                <input type="radio" :id="remoteType.nameCode" :value="remoteType.nameCode"
                       v-model="updatedProfile.profile.remoteOption" class="remote-type-input"/>
                <label :for="remoteType.nameCode" class="remote-type-label"> {{ remoteTypeLabel(remoteType) }}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!--SOCIAL section-->
    <div id="social" class=" row social section" :class="{'bg-red': updateProfileError && updateProfileError.fields.link}">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-share section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <h2>Social Media</h2>
        <p class="advice">
          Verknüpfen Sie hier Ihre <strong>Sozialen Kanäle</strong>, um weitere <strong>Einblicke</strong> in Ihren
          Unternehmensalltag zu geben. Falls Sie <strong>IT-spezifische Accounts</strong> Ihres Unternehmen führen,
          verwenden Sie am besten diese.
        </p>
        <div class="social-flex">
          <a
            v-for="link in updatedProfile.socialMediaLinks"
            :key="link.platform"
            :href="link.link"
            target="_blank"
            class="social-link"
          >
            <div class="social-button">
              <i class="fa"
                 :class="[link.platform === 'website' ? 'fa-globe' : 'fa-' + link.platform + ' ' + link.platform]"/>
            </div>
          </a>
          <div class="social-button social-add-button" @click="showSocialModal = true">
            <i class="fa fa-pencil"/>
            <input
              style="height: 0; width: 0; padding:0; border: none;"
              class="selection"
              @focus="addSelectionClass"
              @blur="removeSelectionClass"
            />
          </div>
        </div>
        <div class="error" v-if="updateProfileError && updateProfileError.fields.link">Bei den Social Media Daten ist
          etwas schief gelaufen. Bitte überprüfen Sie die Links.
        </div>
      </div>
    </div>

    <!--TECH section-->
    <div id="tech" class="row tech-section section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-file-code-o section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="section-header-less-margin">
          <h2>
            Core-Technologien
          </h2>
          <p class="advice">
            Entwickler:innen interessiert nicht nur, mit welcher Programmiersprache sie in Ihrer Firma arbeiten, sondern
            auch,
            welche Frameworks und Werkzeuge genutzt werden. Wählen Sie hier Ihre
            <strong>Core-Technologien</strong> aus, die Sie in Ihrem Unternehmen am meisten verwenden. Sie können bis zu
            sechs Technologien auswählen.
            Core-Technologien können <strong>Programmiersprachen</strong> (z.B. Python),
            <strong>Frameworks</strong> (z.B. Spring (Java)) oder <strong>Werkzeuge</strong> (z. B. Kubernetes) sein.
          </p>
          <p>Sollte eine Technologie nicht vorhanden sein, schicken Sie uns bitte eine E-Mail an
            <a href="mailto:happycustomer@entwicklerheld.de?subject=EntwicklerHeld Profil Technologien">
              happycustomer@entwicklerheld.de
            </a>, wir fügen sie dann umgehend hinzu.
          </p>
        </div>
      </div>
      <div class="offset-lg-2 offset-xl-1 col-12 col-lg-6 col-xl-6">
        <div class="technology-selection-wrapper">
          <multiselect
            v-model="updatedProfile.technologies"
            track-by="id"
            label="label"
            placeholder="Wählen Sie Ihre Core-Technologien aus..."
            :options="updatedProfile.technologyChoices"
            :multiple="true"
            :selectLabel="'Enter zum Auswählen'"
            :deselectLabel="'Enter zum Entfernen'"
            :max="6"
            :selectedLabel="'ausgewählt'"
          >
            <span slot="maxElements">Maximale Anzahl ist erreicht. Deselektieren Sie eine Technologie, um eine neue hinzufügen.</span>
            <span slot="noResult">Die Technologie wurde leider nicht gefunden. Bitte kontaktieren Sie uns!</span>
          </multiselect>
        </div>
      </div>
      <div class="offset-lg-2 offset-xl-1 col-12 col-lg-10 col-xl-11">
        <div class="technology-cards">
          <div
            v-for="(technology, index) in selectedTechnologies"
            :key="technology.id"
            class="technology-section-card technology-card"
          >
            <img :src="technology.logo"/>
            <p>{{ technology.label }}</p>
            <div class="remove-item hvr-grow" @click="selectedTechnologies.splice(index, 1)">
              <i class="fa fa-minus delete remove-item-icon"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--HERO-IMG section-->
    <div id="hero-img" class="row section-header-less-margin section hero-img-section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-image section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <h2>
          Heldenbild
        </h2>
        <p class="advice">
          Ihr <strong>Heldenbild</strong> ist der <strong>Blickfang</strong> schlechthin. Über die ganze Seite erstreckt
          sich das Bild in Ihrem Unternehmensprofil und wird ebenso als Hintergrundbild für Ihr Kärtchen in der
          <a target="_blank" href="https://platform.entwicklerheld.de/company/">Plattform-Unternehmensliste</a>
          verwendet.
          Wählen Sie ein Foto mit <strong>hoher Auflösung</strong> und einer passenden <strong>Botschaft</strong>, die
          Sie
          Ihrer Zielgruppe vermitteln wollen.
        </p>
        <input
          style="height: 0; width: 0; padding:0; border: none;"
          class="selection"
          @focus="addSelectionClass"
          @blur="removeSelectionClass"
        />
      </div>
      <div class="offset-lg-2 offset-xl-1 col-12 col-lg-10 col-xl-11">
        <div class="hero-image-container">
          <div v-if="updatedProfile.profile.heroImage" class="selected-image-container">
            <div class="remove-image hvr-grow">
              <i class="fa fa-minus remove-image-icon" @click="clearHeroImage"/>
            </div>
            <img class="hero-img" :src="updatedProfile.profile.heroImage" alt/>
          </div>

          <label v-if="updatedProfile.profile.heroImage" class="upload">
            Neues Hero Bild hochladen
            <input type="file" @change="loadHeroImage"/>
          </label>
        </div>
      </div>

      <div
        v-if="!updatedProfile.profile.heroImage"
        class="offset-lg-2 offset-xl-1 col-12 col-lg-6 col-xl-6 no-image-selected no-image-panel"
      >
        <div class="no-image-border hvr-grow hero-img-border logo-upload-box">
          <input type="file" @change="loadHeroImage"/>
          <i class="fa fa-image"></i>
          <h4>Heldenbild für Upload auswählen</h4>
          <h5>oder Datei ziehen und hier ablegen</h5>
        </div>
      </div>
    </div>

    <!--PHOTO SLIDER section-->
    <div id="photo-slider" class="row section-header-less-margin section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-camera section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <h2>
          Foto Slider
        </h2>
        <p class="advice">
          Sie haben mehr zu zeigen, als nur ein Bild? Dann nutzen Sie den <strong>Foto Slider</strong>.
          Zeigen Sie weitere Bilder Ihres <strong>Teams</strong> und geben Sie einen Einblick in Ihren
          <strong>Arbeitsalltag</strong>. Achten Sie darauf, dass die Bilder Ihre Unternehmenswerte widerspiegeln.
          Bilder mit Personen wirken generell ansprechender als ein leerer Schreibtisch.
        </p>
        <input
          style="height: 0; width: 0; padding:0; border: none;"
          class="selection"
          @focus="addSelectionClass"
          @blur="removeSelectionClass"
        />
        <div v-if="!sliderImagesComplete" class="alert alert-warning" role="alert">
          Wird im Profil nur angezeigt, wenn <strong>alle</strong> 3 Bilder ausgewählt und hochgeladen wurden.
        </div>
      </div>
      <div class="offset-lg-2 offset-xl-1 col-12 col-lg-10 col-xl-11">
        <div class="row slider-row">
          <div class="col-xl-4 slider-img-col">
            <div v-if="!updatedProfile.profile.sliderImage1" class="no-image-selected no-image-panel">
              <div class="no-image-border hvr-grow hero-img-border logo-upload-box">
                <input type="file" @change="loadSliderImage1"/>
                <i class="fa fa-image"></i>
                <h4>Bild für Upload auswählen</h4>
                <h5>oder Datei ziehen und hier ablegen</h5>
              </div>
            </div>
            <div v-if="updatedProfile.profile.sliderImage1" class="slider-image-container ">
              <div class="remove-item remove-slider hvr-grow" @click="clearSliderImage1">
                <i class="fa fa-minus remove-item-icon"/>
              </div>
              <img class="slider-img slider-img-1" :src="updatedProfile.profile.sliderImage1" alt/>
            </div>
            <label v-if="updatedProfile.profile.sliderImage1" class="upload">
              Anderes Bild hochladen
              <input type="file" @change="loadSliderImage1"/>
            </label>
          </div>
          <div class="col-xl-4 slider-img-col">
            <div v-if="!updatedProfile.profile.sliderImage2" class="no-image-selected no-image-panel">
              <div class="no-image-border hvr-grow hero-img-border logo-upload-box">
                <input type="file" @change="loadSliderImage2"/>
                <i class="fa fa-image"></i>
                <h4>Bild für Upload auswählen</h4>
                <h5>oder Datei ziehen und hier ablegen</h5>
              </div>
            </div>
            <div v-if="updatedProfile.profile.sliderImage2" class="slider-image-container ">
              <div class="remove-item remove-slider hvr-grow" @click="clearSliderImage2">
                <i class="fa fa-minus remove-item-icon"/>
              </div>
              <img class="slider-img slider-img-2" :src="updatedProfile.profile.sliderImage2" alt/>
            </div>
            <label v-if="updatedProfile.profile.sliderImage2" class="upload">
              Anderes Bild hochladen
              <input type="file" @change="loadSliderImage2"/>
            </label>
          </div>
          <div class="col-xl-4 slider-img-col">
            <div v-if="!updatedProfile.profile.sliderImage3" class="no-image-selected no-image-panel">
              <div class="no-image-border hvr-grow hero-img-border logo-upload-box">
                <input type="file" @change="loadSliderImage3"/>
                <i class="fa fa-image"></i>
                <h4>Bild für Upload auswählen</h4>
                <h5>oder Datei ziehen und hier ablegen</h5>
              </div>
            </div>
            <div v-if="updatedProfile.profile.sliderImage3" class="slider-image-container ">
              <div class="remove-item remove-slider hvr-grow" @click="clearSliderImage3">
                <i class="fa fa-minus remove-item-icon"/>
              </div>
              <img class="slider-img slider-img-3" :src="updatedProfile.profile.sliderImage3" alt/>
            </div>
            <label v-if="updatedProfile.profile.sliderImage3" class="upload">
              Anderes Bild hochladen
              <input type="file" @change="loadSliderImage3"/>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!--VIDEO section-->
    <div
      id="video"
      class="row video-area section"
      :class="[updatedProfile.videoUpsale ? 'embed-responsive-16by9' : 'adjust-margin']"
    >
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-film section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="video" :class="[!updatedProfile.videoUpsale ? 'video-locked' : '']">
          <h2>
            Video
          </h2>
          <p class="advice-with-small">
            Bilder sagen mehr als 1000 Worte. Zeigen Sie ihr Unternehmen in einem <strong>Video</strong> direkt in Ihrem
            Profil. Ihr Image-Video können Sie hier einbinden.
          </p>
          <p class="small-advice" :class="{ error: videoError }"
          >(Bitte beachten Sie, dass bisher keine Videos hochgeladen werden können, die größer sind als 60MB. Haben
            Sie ein Video, das größer ist, senden Sie es am besten an
            <a href="mailto:hallo@entwicklerheld.de?subject=Video%20Upload" style="color:#509588;"
            >hallo@entwicklerheld.de</a
            >. Wir kümmern uns dann darum.)</p
          >
          <div class="video-container">
            <div v-if="updatedProfile.videoUpsale && updatedProfile.profile.video" class="selected-image-container">
              <div class="remove-video hvr-grow" @click="clearVideo">
                <i class="fa fa-minus remove-video-icon"/>
              </div>
              <video v-if="updatedProfile.videoUpsale && updatedProfile.profile.video" class="hero-img" controls>
                <source :src="updatedProfile.profile.video"/>
              </video>
            </div>
            <label v-if="updatedProfile.videoUpsale && updatedProfile.profile.video" class="upload">
              Neues Video hochladen
              <input type="file" accept="video/mp4" @change="loadVideo"/>
            </label>
          </div>
          <div
            v-if="updatedProfile.videoUpsale && !updatedProfile.profile.video"
            class="col-12 col-lg-6 col-xl-6 no-image-selected no-image-panel"
          >
            <div class="no-image-border hvr-grow hero-img-border logo-upload-box">
              <input type="file" accept="video/mp4" @change="loadVideo"/>
              <i class="fa fa-image"></i>
              <h4>Video für Upload auswählen</h4>
              <h5>oder Datei ziehen und hier ablegen</h5>
            </div>
          </div>

          <img
            v-if="!updatedProfile.videoUpsale"
            src="https://production-task-management-media-storage.s3.eu-central-1.amazonaws.com/dummy_video_2.jpg"
            class="video-locked"
          />
        </div>
        <span v-if="!updatedProfile.videoUpsale" class="locked-information">
          <span class="premium-header"><i class="fa fa-lock"></i> Video<br/></span>
          <hr/>
          Bilder sagen mehr als 1000 Worte. Zeigen Sie Ihr Unternehmen in einem <strong>Video</strong> direkt in Ihrem
          Profil. Sie möchten mehr Informationen zum EntwicklerHeld <strong>Premium-Profil</strong>? Melden Sie sich
          <br/>
          per Mail an
          <a
            target="_blank"
            href="mailto:hallo@entwicklerheld.de?subject=EntwicklerHeld Premium-Profil"
            class="premium-link"
          >hallo@entwicklerheld.de</a
          >
        </span>
      </div>
    </div>

    <!--VALUES section-->
    <div id="values" class="row values-section section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-users section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="values" :class="[!updatedProfile.valuesUpsale ? 'values-locked' : '']">
          <div class="section-header">
            <h2>
              Werte
            </h2>
            <p class="advice">
              Was macht Ihr Unternehmen besonders? Stellen Sie die <strong>Werte</strong> heraus, die Sie vertreten,
              wofür Sie sich einsetzen und
              sprechen Sie Entwickler:innen direkt an.
            </p>
          </div>
          <div class="values-cards">
            <div v-for="value in selectedValues" :key="value.nameCode" class="value-section-card value-card">
              <div class="remove-value" @click="removeValue(value.nameCode)">
                <i class="fa fa-minus"/>
              </div>
              <img :src="value.icon"/>
              <p>{{ updatedProfile.valueChoices.find((val) => val.nameCode === value.nameCode).name }}</p>
            </div>
            <div
              v-if="updatedProfile.values.length < 6"
              class="value-section-card add-value"
              @click="showValueModal = true"
            >
              <i class="fa fa-plus"/>
            </div>
          </div>
          <div class="values-desc">
            <div v-for="(value, i) in updatedProfile.values" :key="value.nameCode" class="value-section without-img">
              <div class="value-description">
                <img :src="value.icon"/>
                <input v-model="updatedProfile.values[i].headline" class="value-headline" type="text"/>
                <textarea-autosize
                  v-model.trim="updatedProfile.values[i].description"
                  placeholder="Bitte beschreiben Sie die Bedeutung des Wertes"
                />
              </div>
            </div>
          </div>
        </div>
        <span v-if="!updatedProfile.valuesUpsale" class="locked-information locked-values-info">
          <span class="premium-header"><i class="fa fa-lock"></i> Ihre Werte<br/></span>
          <hr/>
          Was macht Ihr Unternehmen besonders? Stellen Sie ihre <strong>Werte</strong> heraus die Sie vertreten und
          sprechen Sie möchten mehr Informationen zum EntwicklerHeld <strong>Premium-Profil</strong>? Melden Sie sich
          <br/>
          per Mail an
          <a
            target="_blank"
            href="mailto:hallo@entwicklerheld.de?subject=EntwicklerHeld Premium-Profil"
            class="premium-link"
          >hallo@entwicklerheld.de</a
          >
        </span>
      </div>
    </div>

    <!--STATS section-->
    <div id="stats" class="row stats-section section">
      <div class="col-12 col-lg-2 col-xl-1 d-flex justify-content-center">
        <i class="fa fa-line-chart section-icon"/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="statistics" :class="[!updatedProfile.statisticsUpsale ? 'statistics-locked' : '']">
          <div class="section-header">
            <h2>
              Statistiken
            </h2>
            <p class="advice">
              Machen Sie Ihr Profil noch interessanter und kommunizieren Sie <strong>Statistiken</strong>, die Ihr
              Unternehmen ausmachen.
            </p>
          </div>
          <div class="statistics-cards">
            <div
              v-for="statistic in selectedStatistics"
              :key="statistic.nameCode"
              class="stat-section-card statistic-card"
            >
              <div class="remove-statistic" @click="removeStat(statistic.nameCode)">
                <i class="fa fa-minus remove-icon"></i>
              </div>
              <i class="fa fas fal fab far stat-icon" :class="'fa-' + statistic.icon"></i>
              <div class="statistic-desc">
                <input v-model="statistic.value" type="text" class="statistic-value"/>
                <span class="statistic-name">{{
                    updatedProfile.statisticChoices.find((stat) => stat.nameCode === statistic.nameCode).name
                  }}</span>
              </div>
            </div>
            <div
              v-if="updatedProfile.statistics.length < 6"
              class="stat-section-card add-statistic"
              @click="showStatisticModal = true"
            >
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
        <span v-if="!updatedProfile.statisticsUpsale" class="locked-information">
          <span class="premium-header"><i class="fa fa-lock"></i> Statistiken<br/></span>
          <hr/>
          Machen Sie Ihr Profil noch interessanter und kommunizieren Sie <strong>Statistiken</strong>, die Ihr
          Unternehmen ausmachen. Sie möchten mehr Informationen zum EntwicklerHeld <strong>Premium-Profil</strong>?
          Melden Sie sich <br/>
          per Mail an
          <a
            target="_blank"
            href="mailto:hallo@entwicklerheld.de?subject=EntwicklerHeld Premium-Profil"
            class="premium-link"
          >hallo@entwicklerheld.de</a
          >
        </span>
      </div>
    </div>
  </div>
  <div v-else class="loading-container">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import {CompanyProfileResult, EmployeeByUserIdResult} from "@/services/company/CompanyServiceResponses";
import {REMOTE_TYPE_HELP_TEXTS} from "@/const";
import {services} from "@/main";
import PValueModal from "@/components/profile/PValueModal";
import PStatisticModal from "@/components/profile/PStatisticModal";
import PSocialModal from "@/components/profile/PSocialModal";
import {eventBus} from "@/eventbus";
import {Multiselect} from "vue-multiselect";


export default {
  components: {
    PValueModal,
    PStatisticModal,
    PSocialModal,
    Multiselect,
  },
  props: {},
  data() {
    return {
      companyProfile: new CompanyProfileResult(),
      employeeByUserIdRequest: new EmployeeByUserIdResult(),
      updatedProfileResult: null,
      updatedProfile: null,
      profileCopied: false,
      showValueModal: false,
      showStatisticModal: false,
      showSocialModal: false,
      enteredAddress: "",
      enteredCareerPage: "",
      currentLocation: null,
      logo: null,
      videoError: false,
    };
  },
  computed: {
    availableValueChoices: function () {
      return this.updatedProfile.valueChoices.filter(
        (choice) => !this.updatedProfile.values.some((value) => value.nameCode === choice.nameCode)
      );
    },
    updateProfileError: function () {
      if (this.updatedProfileResult && this.updatedProfileResult.loaded && this.updatedProfileResult.error) {
        return this.updatedProfileResult.error.additionalData;
      }
      return null;
    },
    availableStatisticChoices: function () {
      return this.updatedProfile.statisticChoices.filter(
        (choice) => !this.updatedProfile.statistics.some((statistic) => statistic.nameCode === choice.nameCode)
      );
    },
    selectedValues: function () {
      if (!this.companyProfile.apiData.valuesUpsale) {
        return [
          this.companyProfile.apiData.valueChoices[1],
          this.companyProfile.apiData.valueChoices[3],
          this.companyProfile.apiData.valueChoices[5],
          this.companyProfile.apiData.valueChoices[7],
        ];
      }

      return this.updatedProfile.values;
    },
    selectedStatistics: function () {
      if (!this.companyProfile.apiData.statisticsUpsale) {
        return [
          this.companyProfile.apiData.statisticChoices[1],
          this.companyProfile.apiData.statisticChoices[3],
          this.companyProfile.apiData.statisticChoices[5],
          this.companyProfile.apiData.statisticChoices[7],
        ];
      }

      return this.updatedProfile.statistics;
    },
    selectedTechnologies: function () {
      return this.updatedProfile.technologies;
    },
    sliderImagesComplete: function () {
      return (
        this.companyProfile.apiData.profile.sliderImage1 &&
        this.companyProfile.apiData.profile.sliderImage2 &&
        this.companyProfile.apiData.profile.sliderImage3
      );
    },
    companyProfileSlug: function () {
      return this.companyProfile.apiData.companySlug;
    }

  },
  watch: {
    "companyProfile.loaded": function () {
      this.updatedProfile = this.companyProfile.apiData;
      this.enteredCareerPage = this.updatedProfile.profile.careerPage;

      // Get icon and name fields from valueChoices
      this.updatedProfile.values.forEach((value) => {
        value.icon = this.updatedProfile.valueChoices.find(
          (valueChoice) => valueChoice.nameCode === value.nameCode
        ).icon;
        value.name = this.updatedProfile.valueChoices.find(
          (valueChoice) => valueChoice.nameCode === value.nameCode
        ).name;
      });

      // Get icon and name fields from statisticChoices
      this.updatedProfile.statistics.forEach((statistic) => {
        statistic.icon = this.updatedProfile.statisticChoices.find(
          (statisticChoice) => statisticChoice.nameCode === statistic.nameCode
        ).icon;
        statistic.name = this.updatedProfile.statisticChoices.find(
          (statisticChoice) => statisticChoice.nameCode === statistic.nameCode
        ).icon;
      });

      // Sort statistic and value choices alphabetically by name
      this.updatedProfile.statisticChoices.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      this.updatedProfile.valueChoices.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

      this.profileCopied = true;
      this.$nextTick(() => {
        if (this.$route.hash) {
          let elementId = this.$route.hash.replace("#", "");
          let element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView({behavior: "smooth", block: "center"});
            let selectionElement = element.getElementsByClassName("selection");
            if (selectionElement.length > 0) {
              setTimeout(() => {
                selectionElement[0].focus();
              });
            }
          }
        }
      });
    },
    "employeeByUserIdRequest.loaded": function () {
      if (!this.employeeByUserIdRequest.hasLoaded()) {
        return;
      }

      let companyId = this.employeeByUserIdRequest.apiData.company.id;
      this.companyProfile = services.companyService.getCompanyProfile(companyId);
    },
    "updatedProfileResult.loaded": function () {
      if (!this.updatedProfileResult.loaded) {
        return;
      }

      if (!this.updatedProfileResult.error) {
        this.$notify({
          group: "updated-profile",
          type: "success",
          title: "Success",
          text: "Das Speichern des Profils war erfolgreich.",
        });
        eventBus.$emit("updatedProfileLoaded");
      } else {
        const errorMessage = `Das Speichern des Profils war nicht erfolgreich.`;
        this.jumpToError(Object.keys(this.updateProfileError.fields)[0]);
        return this.$notify({
          group: "updated-profile",
          type: "error",
          title: "Error",
          text: errorMessage,
        });
      }
    },
  },
  mounted() {
    this.employeeByUserIdRequest = services.companyService.getEmployeeByUserId();
  },
  methods: {
    fieldNameToAnchor: function (fieldName) {
      if (fieldName === "link") {
        return "social";
      }
    },
    jumpToError(errorField) {
      const anchor = this.fieldNameToAnchor(errorField);
      let element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({behavior: "smooth", block: "center"});
        let selectionElement = element.getElementsByClassName("selection");
        if (selectionElement.length > 0) {
          setTimeout(() => {
            selectionElement[0].focus();
          });
        }
      }
    },
    remoteTypeLabel: function (remoteType) {
      let helpText = REMOTE_TYPE_HELP_TEXTS[remoteType.nameCode] ?? "";
      let labelText = remoteType.name + ": " + helpText;
      return labelText;
    },
    addSelectionClass: function (event) {
      event.target.closest(".section").classList.add("selection-background");
    },
    removeSelectionClass: function (event) {
      event.target.closest(".section").classList.remove("selection-background");
    },
    loadLogo: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updatedProfile.profile.logo = reader.result;
      };
    },
    clearLogo: function () {
      this.updatedProfile.profile.logo = "";
    },
    loadHeroImage: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updatedProfile.profile.heroImage = reader.result;
      };
    },
    clearHeroImage: function () {
      this.updatedProfile.profile.heroImage = "";
    },
    loadSliderImage1: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updatedProfile.profile.sliderImage1 = reader.result;
      };
    },
    loadSliderImage2: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updatedProfile.profile.sliderImage2 = reader.result;
      };
    },
    loadSliderImage3: function (e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updatedProfile.profile.sliderImage3 = reader.result;
      };
    },
    clearSliderImage1: function () {
      this.updatedProfile.profile.sliderImage1 = "";
    },
    clearSliderImage2: function () {
      this.updatedProfile.profile.sliderImage2 = "";
    },
    clearSliderImage3: function () {
      this.updatedProfile.profile.sliderImage3 = "";
    },
    loadVideo(e) {
      const file = e.target.files[0];
      if (file.size >= 1048576 * 60) {
        this.videoError = true;
        return;
      }
      this.videoError = false;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updatedProfile.profile.video = reader.result;
      };
    },
    clearVideo: function () {
      this.updatedProfile.profile.video = "";
    },
    removeValue: function (nameCode) {
      this.updatedProfile.values.splice(
        0,
        this.updatedProfile.values.length,
        ...this.updatedProfile.values.filter((value) => value.nameCode !== nameCode)
      );
    },
    removeStat: function (nameCode) {
      this.updatedProfile.statistics.splice(
        0,
        this.updatedProfile.statistics.length,
        ...this.updatedProfile.statistics.filter((stat) => stat.nameCode !== nameCode)
      );
    },
    addValue: function ({nameCode, name, icon}) {
      this.updatedProfile.values.push({
        id: nameCode,
        nameCode,
        name: name,
        headline: name,
        description: "",
        icon: icon,
        image: "",
      });
      this.showValueModal = false;
    },
    addStatistic: function ({nameCode, name, icon}) {
      this.updatedProfile.statistics.push({
        id: nameCode,
        nameCode,
        name,
        value: "0",
        icon,
      });
      this.showStatisticModal = false;
    },
    changeCareerPage: function () {
      this.updatedProfile.profile.careerPage = this.enteredCareerPage;
    },
    addSocial: function (links) {
      for (let [platform, link] of Object.entries(links)) {
        if (this.updatedProfile.socialMediaLinks.find((entry) => entry.platform === platform)) {
          let position = this.updatedProfile.socialMediaLinks.findIndex((entry) => entry.platform === platform);
          // delete if not set
          if (!link) {
            this.updatedProfile.socialMediaLinks.splice(position, 1);
            continue;
          }
          this.updatedProfile.socialMediaLinks[position].link = link;
        } else if (link) {
          this.updatedProfile.socialMediaLinks.push({
            platform,
            link,
          });
        }
      }
      this.showSocialModal = false;
    },
    onPlaceChanged: function (data) {
      this.currentLocation = data;
    },
    addLocation: function () {
      this.updatedProfile.locations.push({
        address: this.currentLocation.street_number
          ? this.currentLocation.route + " " + this.currentLocation.street_number
          : this.currentLocation.route,
        zipCode: this.currentLocation.postal_code,
        city: this.currentLocation.locality,
        country: this.currentLocation.country,
        latitude: this.currentLocation.latitude,
        longitude: this.currentLocation.longitude,
      });
      this.enteredAddress = "";
      this.currentLocation = null;
    },
    deleteLocation: function (clickedLocation) {
      let position = this.updatedProfile.locations.findIndex(
        (location) => location.address === clickedLocation.address
      );
      this.updatedProfile.locations.splice(position, 1);
    },
    updateProfile: async function () {
      const profileId = this.updatedProfile.profileId.toString();
      const profile = {
        profile: this.updatedProfile.profile,
        values: this.updatedProfile.values,
        locations: this.updatedProfile.locations,
        socialMediaLinks: this.updatedProfile.socialMediaLinks,
        statistics: this.updatedProfile.statistics,
        technologies: this.selectedTechnologies,
      };
      profile.values.forEach((value) => {
        delete value.name;
        delete value.id;
      });
      profile.profile.logoIcon = "";

      this.updatedProfileResult = await services.companyService.updateCompanyProfile(profileId, profile);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.technology {
  border-radius: 3px;
  color: black;
  font-family: "Source Code Pro", Menlo, Monaco, "Courier New", monospace;
  background: rgba(41, 128, 185, 0.1);
  padding: 0 6px;
  font-size: 13px;
  margin-right: 1rem;
  white-space: normal;
  display: inline-block;
  margin-bottom: 0.2rem;
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.profile-preview-link {
  padding: 0 0 3rem 2rem;
}

.logo-icon {
  margin-bottom: 6rem;
}

.logo {
  display: block;
  max-height: 400px;
  max-width: 100%;
}

h1,
.h1 {
  font-size: 33px;
}

h2,
.h2 {
  font-size: 27px;
  padding-top: 0.5rem;
}

h3,
.h3 {
  font-size: 23px;
}

h4,
.h4 {
  font-size: 17px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

p {
  font-size: 13px;
  margin: 0 0 9px;
}

textarea {
  width: 100%;
  overflow: hidden;
  resize: none;
}

input,
textarea,
.white-box-text {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 1rem 1rem;

  &:focus {
    outline: none;
    border-color: $eh-green;
  }
}

input[type="radio"].remote-type-input {
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

label.remote-type-label {
  display: inline-block;
  margin-left: 1rem;
  margin-bottom: 0;
  vertical-align: middle;
}

.upload {
  position: relative;
  overflow: hidden;
  display: inline-block;

  /* Fancy button style 😎 */
  background-color: $eh-green;
  border-radius: 100px;
  font-weight: bold;
  padding: 8px 20px;
  cursor: pointer;
  color: white;
  opacity: 0.9;
}

.upload:hover {
  opacity: 1;
}

.upload input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.logo-upload-box input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.slogan {
  font-size: 1.6rem;
  margin-bottom: 6rem;

  textarea {
    text-align: left;
  }
}

.values {
  text-align: left;
  width: 100%;
  position: relative;
}

.values-locked {
  pointer-events: none;
  filter: blur(4px) grayscale(1);
  -webkit-filter: blur(4px) grayscale(1);
}

.locked-information > hr {
  border-color: white;
}

.locked-information {
  position: absolute;
  top: 15%;
  left: 5%;
  font-size: 1.3rem;
  color: white;
  font-weight: 500;
  background: rgba(34, 113, 113, 0.5);
  text-align: left;
  padding: 4rem;
  border-radius: 10px;
  backdrop-filter: blur(30px);
}

.section-header {
}

.section-header-less-margin {
  text-align: left;
  margin-bottom: 2rem;
}

.values-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: -1rem;
  margin-bottom: 6rem;

  & > * {
    margin: 1rem;
  }
}

.technology-selection-wrapper::v-deep .multiselect__tag {
  background: #509588;
  border-radius: 100px;
}

.technology-selection-wrapper::v-deep .multiselect__option--highlight {
  background: #509588;
}

.technology-selection-wrapper::v-deep .multiselect__option--highlight:after {
  background: #398b7d;
}

.technology-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: -1rem;

  & > * {
    margin: 1rem;
  }
}

.value-section-card {
  width: 15rem;
  border-radius: 10px;
}

.technology-section-card {
  width: 15rem;
  border-radius: 10px;
}

.bg-red {
  background-color: rgba(255, 92, 0, 0.1)!important;
}

.add-value {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 2px dashed #bbb;
  font-size: 3rem;
  color: #bbb;
  background-color: transparent;
  padding: 2rem;
}

.technology-card span {
  margin: auto;
  font-size: 2rem;
}

.value-card {
  background: white linear-gradient(135deg, white, #f6f8fa);
  box-sizing: border-box;
  box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);
  padding: 1.5rem 3rem;
  position: relative;

  img {
    height: 4rem;
    margin-bottom: 1rem;
  }

  p {
    margin: 0;
  }
}

.technology-card {
  background: white linear-gradient(135deg, white, #f6f8fa);
  box-sizing: border-box;
  box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);
  padding: 1.5rem;
  position: relative;
  text-align: center;

  img {
    height: 4rem;
    margin-bottom: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto
  }

  p {
    margin: 0;
  }
}

.remove-value {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  background-color: crimson;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  i {
    font-size: 1rem;
    margin: 0;
    color: white;
  }
}

.value-section {
  background: repeating-linear-gradient(to right bottom, #f6f9fc 0%, #dadfe6 100%);
  box-sizing: border-box;
  padding: 3rem;
  display: flex;
  width: 100%;
  overflow: hidden;
  text-align: left;

  img {
    flex: 1;
    max-width: 100%;
    object-fit: cover;
  }
}

.without-img {
  text-align: left;
  display: flex;
  justify-content: left;

  .value-description {
    width: 60%;

    textarea,
    input {
      text-align: left;
      width: 100%;
    }
  }
}

.value-description {
  box-sizing: border-box;
  min-width: 40%;

  img {
    height: 6rem;
    margin-bottom: 2rem;
  }

  .value-headline {
    margin-bottom: 2rem;
    font-size: 2rem;
    display: block;
  }
}

.values-section {
  position: relative;
  width: 100%;
  margin-bottom: 6rem;
}

.stats-section {
  position: relative;
  width: 100%;
}

.statistics {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;

  .section-icon {
    background: #f39c12;
  }
}

.statistics-locked {
  filter: blur(4px) grayscale(1);
  -webkit-filter: blur(4px) grayscale(1);
  pointer-events: none;
}

.statistics-cards {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
  margin-bottom: 6rem;
  width: 60vw;

  & > * {
    margin: 1rem;
  }
}

.stat-section-card {
  display: flex;
  border-radius: 10px;
  width: 30%;
  box-sizing: border-box;
  padding: 2rem 1rem;
}

.add-statistic {
  border: 2px dashed #bbb;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #bbb;
  background-color: transparent;
}

.remove-statistic {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 1rem;
  left: 1rem;
  border-radius: 50%;
  background-color: #ea3c53;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9997;

  &:hover {
    cursor: pointer;
  }

  .remove-icon {
    font-size: 1rem;
    margin: 0;
    color: white;
  }
}

.statistic-card {
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);

  &:nth-of-type(1) {
    background: repeating-linear-gradient(to right bottom, #2d8dcc 0%, #287db6 100%);
  }

  &:nth-of-type(2) {
    background: repeating-linear-gradient(to right bottom, #32adcc 0%, #3aa5b6 100%);
  }

  &:nth-of-type(3) {
    background: repeating-linear-gradient(to right bottom, #2accb6 0%, #5fb6a6 100%);
  }

  &:nth-of-type(4) {
    background: repeating-linear-gradient(to right bottom, #2ab986 0%, #29b96d 100%);
  }

  &:nth-of-type(5) {
    background: repeating-linear-gradient(to right bottom, #2ab966 0%, #2ab966 100%);
  }

  &:nth-of-type(6) {
    background: repeating-linear-gradient(to right bottom, #64b950 0%, #5db94b 100%);
  }

  .statistic-desc {
    display: flex;
    flex-direction: column;
    text-align: right;
    color: white;

    .statistic-value {
      font-size: 1.6rem;
      text-align: right;
      color: white;
      align-self: flex-end;
      width: 50%;
      border-color: white;
      background-color: transparent;
    }

    .statistic-name {
      font-size: 0.8rem;
    }
  }

  .stat-icon {
    color: white;
    opacity: 0.5;
    position: absolute;
    font-size: 6rem;
    bottom: -10%;
    left: 0;
  }
}

.locations {
  text-align: left;
}

.input-address-section {
  display: flex;
  justify-content: left;
}

.input-address {
  display: block;
  width: 30vw;
  text-align: left;
  padding: 1rem;
  margin-right: 2rem;
}

.add-location {
  padding: 1rem 2rem;
  background-color: $eh-green;
  color: white;
  font-weight: bold;
  border-radius: 100px;
  border: 0;
  box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);

  &:focus {
    outline: none;
  }
}

.input-career-page-section {
  display: flex;
  justify-content: left;
}

.input-career-page {
  display: block;
  width: 40vw;
  text-align: left;
  padding: 1rem;
}


.add-tech-button {
  opacity: 0.9;
}

.add-tech-button:hover {
  opacity: 1;
}

.button-grey {
  cursor: not-allowed;
  background-color: #cccccc;
}

.add-location-button {
  opacity: 0.9;
}

.add-location-button:hover {
  opacity: 1;
}

.added-locations {
  margin-top: 2rem;

  .location {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    width: 100%;

    .delete-location {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
      background-color: #ea3c53;
      color: white;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      opacity: 0.8;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }

    p {
      margin: 0;
    }
  }
}

.hero-img {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 13px;
}

.slider-row {
  width: 100%;
}

.slider-img {
  object-fit: cover;
  height: 400px;
  width: 100%;
  margin-bottom: 1rem;
}

.no-image-selected {
  font-size: 4rem;
  width: 400px;
  height: auto;
  color: $eh-green;
  text-align: center;
}

.no-image-border {
  border: 2px solid $eh-green;
  padding: 4rem 2rem;
  border-radius: 2rem;
  margin: 1.5rem;
  width: 80%;
  height: 80%;
}

.hero-img-section .no-image-border {
  width: 85%;
}

.no-image-panel {
  padding: 1rem;
  background-color: white;
  background: linear-gradient(135deg, white, #f6f8fa);
  border-radius: 3rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  height: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 10px 22px 0px rgba(50, 50, 50, 0.09);
  -webkit-box-shadow: 0px 10px 22px 0px rgba(50, 50, 50, 0.09);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.image-panel {
  padding: 3rem;
  background-color: white;
  background: linear-gradient(135deg, white, #f6f8fa);
  border-radius: 3rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 10px 22px 0px rgba(50, 50, 50, 0.09);
  -webkit-box-shadow: 0px 10px 22px 0px rgba(50, 50, 50, 0.09);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

@media (max-width: 1200px) {
  .no-image-panel {
    width: 300px;
    height: 300px;
  }
  .no-image-border {
    padding: 1rem;
  }
}

.hero-image-container,
.video-container {
  position: relative;
  text-align: center;
  padding: 0;
}

.slider-img-col {
  text-align: center;
  padding: 0;
}

.delete-image-button {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 3rem;
}

.video-section {
  position: relative;
}

.embed-responsive {
  margin-bottom: 1rem;
  min-height: 20vh;
}

.video-locked {
  filter: blur(4px) grayscale(0);
  -webkit-filter: blur(4px) grayscale(0);
  pointer-events: none;
  width: 80%;
  margin: 0;
  display: block;
  padding: 1rem;
}

.video-area {
  min-height: 500px;
  margin-bottom: 1rem;
}

.adjust-margin {
  margin-bottom: 6rem;
}

.premium-header {
  font-size: 3rem;
  font-weight: 100;
}

.premium-link {
  color: inherit;
  font-weight: bold;
  text-decoration: underline;
}

.social {
  margin-bottom: 6rem;
}

.social-button {
  font-size: 2rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  color: white;
  background: $eh-green;
  box-sizing: border-box;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.12);

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  i.kununu {
    background-image: url("../../assets/kununu-logo.png");
    color: #2867b2;
    width: 30px;
    height: 30px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.social-flex {
  display: flex;
  flex-wrap: wrap;
}

.social-link:hover {
  text-decoration: none;
  opacity: 1;
}

.social-add-button {
  background-color: transparent;
  border: 2px dashed #bbb;
  color: #bbb;
  margin-right: 0;
  opacity: 0.7;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.social-add-button i {
  margin-right: -1px;
}

.save-button {
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  border: 0;
  z-index: 1000;
  background: linear-gradient(110.16deg, #38887a 3.84%, #58b89a 93.74%), #2f3ebf;
  text-transform: uppercase;
  border: none;
  padding: 1rem 1.75rem;
  border-radius: 999px;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  opacity: 0.9;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }
}

/*--------------------------LOADER-------------------------------*/
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 30vw;
  height: 30vh;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.loading-container {
  position: absolute;
  display: flex;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  background-color: rgba(80, 151, 151, 0.3);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 1000px) {
  .premium-header {
    font-size: 2rem;
  }
  .locked-information {
    padding: 2rem;
    font-size: 1rem;
  }
  .video-locked:after {
    padding-bottom: 100%;
  }

  .locked-information {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 500px) {
  .slogan {
    width: 100%;

    textarea {
      width: 100%;
      font-size: 1.4rem;
    }
  }

  .value-section {
    padding-left: 0;
    padding-right: 0;
  }

  .value-description {
    width: 90% !important;
  }

  .value-headline {
    font-size: 1.4rem !important;
  }

  .statistics-cards {
    width: 90vw;
  }

  .statistic-card {
    width: 100%;
  }

  .add-statistic {
    width: 100%;
  }

  .save-button {
    width: 90vw;
    left: 5vw;
  }
}

.save-button-deleted {
  background-color: grey;
}

.value-image-upload {
  margin-top: 2rem;
}

.value-description {
  margin-right: 2rem;
}

@media screen and (max-width: 1200px) {
  .value-section {
    flex-direction: column;
  }

  .value-image {
    max-width: 100% !important;
  }
}

.tech-section {
  width: 100%;
  text-align: left;
  margin-bottom: 2rem;
}

.large-tooltip .tooltip-inner {
  width: 1000px !important;
  max-width: 1000px !important;
}

.tech-section {
  width: 100%;

  .tech-content-wrapper {
    width: 50%;
  }

  .add-tech {
    display: flex;
    margin-bottom: 2rem;

    input {
      flex-grow: 1;
      margin-right: 1rem;
      margin-left: 0;
    }

    button {
      background-color: $eh-green;
      border: none;
      border-radius: 100px;
      color: white;
      box-sizing: border-box;
      padding: 0 1.4rem;
    }
  }

  ul {
    margin-top: 2rem;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 35%;
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    margin-left: 1rem;

    .description {
      margin-right: 2rem;
    }

    .delete {
      color: crimson;
      font-size: 1.6rem;

      &:hover {
        cursor: pointer;
      }
    }

    .active-color {
      color: black;
    }

    .example-color {
      color: #a8a8a8;
    }
  }
}

.company-technologies {
  position: relative;
  font-family: "Source Code Pro", sans-serif;
  color: white;
  height: 20rem;
  margin-bottom: 2px;
  background-color: #dcdbdc;
  line-height: 1.6;
  box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);
  -webkit-box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);
}

.remove-item-icon {
  color: white;
  margin: auto;
  font-size: 1rem;
}

.remove-item {
  position: absolute;
  display: flex;
  align-items: center;
  top: -0.5rem;
  right: -0.7rem;
  color: crimson;
  font-size: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: crimson;
}

.hvr-grow {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}

.list-item {
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: rotateY(-90deg);
  transition: cubic-bezier(0.36, -0.64, 0.34, 1.76);
  transition-duration: 1s;
}

.technology-default-logo {
  font-size: 35px;
  word-break: break-word;
  color: white;
  background-color: #2f979873;
  overflow: hidden;
  padding: 2px 35px;
  border-radius: 13px 0 13px 0;
  box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);
  -webkit-box-shadow: 0 10px 22px 0 rgba(50, 50, 50, 0.09);
  display: inline-block;
  margin: 0 1rem;
}

.technology-row .technology-col {
  margin-bottom: 4rem !important;
  padding-left: 0;
}

@media (max-width: 768px) {
  .technology-row .technology-col {
    width: 100%;
    margin-bottom: 2rem !important;
  }
}

.silver-panel {
  font-family: "Russo One", sans-serif;
  background-color: white;
  background: linear-gradient(135deg, white, #f6f8fa);
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.5rem 0;
  width: 100%;
  position: relative;
  height: 100%;
  box-shadow: 0px 10px 22px 0px rgba(50, 50, 50, 0.09);
  -webkit-box-shadow: 0px 10px 22px 0px rgba(50, 50, 50, 0.09);
  transition: all 300ms linear;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.advice {
  margin-bottom: 2rem;
}

.advice-with-small {
  margin-bottom: 0;
}

.small-advice {
  color: grey;
  font-size: 11px;
  margin-top: 0;
  margin-bottom: 2rem;
}

.error {
  color: #e74c3c;
}

.input-text {
  font-size: 15px;
}

.white-box-text {
  background-color: white;
  position: relative;
}

@media (max-width: 1024px) {
  textarea {
    width: 100%;
  }
  .advice {
    width: 100%;
  }
}

.remove-image-icon,
.remove-video-icon {
  color: white;
  margin: auto;
  font-size: 1.5rem;
}

.remove-image,
.remove-video {
  position: absolute;
  display: flex;
  top: 1rem;
  right: 1rem;
  justify-content: flex-end;
  align-items: center;
  color: crimson;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: crimson;
  z-index: 5;
}

.selected-image-container.image-panel {
  position: relative;
}

.selected-image-container .slider-image-container {
  position: relative;
}

.selected-image-container.image-panel {
  margin-bottom: 1rem;
  width: 100%;
}

@media (max-width: 414px) {
  .selected-image-container img {
    max-width: 100%;
  }
}

.selected-image-wrapper {
  display: inline-block;
  text-align: center;
}

.remove-slider {
  top: 1rem;
  right: 1rem;
}

.remove-logo {
  width: 2rem;
  height: 2rem;
  top: 0;
  right: 0;
}

.selection-background {
  background-color: (#eef5f4);
}

.section {
  width: 100%;
  margin-bottom: 6rem;
  padding: 2rem 0;
  transition: background-color 0.5s ease-in-out;
}

.wrapper .main {
  padding: 0 !important;
}

.section-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fafafa;
  font-size: 1.25rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: $eh-green;
}

.location-wrapper {
}

@media (max-width: 1024px) {
}

.row {
  margin-left: 0;
}

@media screen and (max-width: 992px) and (min-width: 720px) {
  .location-wrapper {
    display: grid;
    justify-content: center;
  }
}

//col-sm
@media (max-width: 720px) {
  .input-address-section {
    flex-direction: column;
  }

  .input-address {
    width: 100%;
    margin-bottom: 1rem;
  }

  .location {
    flex-direction: column;

    .delete-location {
      margin-bottom: 1rem;
      margin-right: 0 !important;
    }
  }
}

//col-xs
@media (max-width: 576px) {
}

@media (max-width: 1620px) {
  .col-xl-4.slider-img-col {
  }
  .no-image-panel {
    max-width: 95%;
    margin-bottom: 1rem;
  }
}

@media (min-width: 1201px) {
  .slider-img.slider-img-1 {
    border-radius: 13px 0 0 13px;
  }
  .slider-img.slider-img-3 {
    border-radius: 0 13px 13px 0;
  }
}

@media (max-width: 1200px) {
  .slider-img {
    border-radius: 13px;
  }
}

.header {
  width: 100%;
}

.profile-heading {
  font-size: 3rem;
  font-weight: 500;
  padding: 1.5rem 0 3rem 2rem;
  border-radius: 0 99px 99px 0;
}

.header-background {
  /*border-radius: 0 99px 99px 0;
    position: relative;
    margin: 0 60rem 0 -1rem;
    height: 200px;
    z-index: 1;
    opacity: 0.9;
    -webkit-backface-visibility: hidden;*/
}

.waves {
  position: absolute;
  top: 9.8rem;
  height: 100px;
  width: 100%;
}

.cls-1,
.cls-2 {
  fill: #fafafa;
}

.cls-2 {
  opacity: 0.06;
}

//col-md
@media (max-width: 992px) {
  h2,
  .advice,
  .img,
  .section,
  .location-wrapper,
  .career-page-wrapper,
  .profile-heading {
    text-align: center;
  }
  .social-flex,
  .values-cards,
  .technology-cards {
    justify-content: center;
  }
  .value-description {
    margin-right: 0;
    text-align: center;
    justify-content: center;
  }
  .without-img .value-description {
    width: 100%;
  }
  .slider-row {
    justify-content: center;
  }
  .profile-heading {
    padding: 0;
    font-size: 30px;
  }
}
</style>
