<script>
import { services } from "@/main";

export default {
  async beforeRouteEnter(to, from, next) {
    const isAuthenticated = services.authenticationService.isAuthenticated();
    if (!isAuthenticated) {
      if (to.name === "register" || to.name === "register-with-code") {
        return next();
      }
      services.authenticationService.login(to);
      return next(false);
    }

    let result = await services.companyService.getEmployeeByUserIdPromise();

    if (result.error) {
      return next("/error?next=" + to.path);
    }

    if (to.name === "afterLoginRedirect" && result.apiData.isRegistered) {
      if (!result.apiData.isCompanyActive) {
        return next("/profile");
      }
      if (services.authenticationService.isPayingCustomer()) {
        return next("/candidates");
      }
      return next("/products/vacancy");
    }

    if (!result.apiData.isRegistered && to.name !== "register") {
      return next("/register");
    }
    if (result.apiData.isRegistered && to.name === "register") {
      return next("/profile");
    }

    return next();
  },
};
</script>

<style scoped></style>
