<template>
  <div class="modal-mask" @click="$emit('close')">
    <div class="modal-container" @click.stop>
      <div v-for="choice in choices" :key="choice.code" class="choice" @click="$emit('addstatistic', choice)">
        <i class="fa" :class="'fa-' + choice.icon" />
        <p>{{ choice.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    choices: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  max-height: 50vh;
  margin: 0px auto;
  padding: 20px 60px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  overflow-x: auto;
}

.choice {
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
  cursor: pointer;

  &:nth-of-type(1n + 0) i {
    color: #2d8dcc;
  }

  &:nth-of-type(2n + 0) i {
    color: #32adcc;
  }

  &:nth-of-type(3n + 0) i {
    color: #2accb6;
  }

  &:nth-of-type(4n + 0) i {
    color: #2ab986;
  }

  &:nth-of-type(5n + 0) i {
    color: #2ab966;
  }

  &:nth-of-type(6n + 0) i {
    color: #64b950;
  }

  i {
    margin-right: 1rem;
    font-size: 2rem;
    width: 2rem;
    text-align: center;
  }

  p {
    margin: 0;
    font-weight: bold;
    font-size: 1.4rem;
  }
}
</style>
