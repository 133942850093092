<template>
  <div>
    <c-profile-mask />
  </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import CProfileMask from "@/components/profile/CProfileMask";

export default {
  components: {
    CProfileMask,
  },
  mixins: [AuthenticatedMixin],
  data() {
    return {
      company_name: "",
      slogan: "",
      teaser: "",
      facebook_value: "",
      twitter_value: "",
      instagram_value: "",
      xing_value: "",
      linkedin_value: "",
      logo: null,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#logo-preview {
  height: 200px;
}
</style>
