<template>
  <div class="modal-mask" @click="$emit('close')">
    <div class="modal-container" @click.stop>
      <div v-for="choice in choices" :key="choice.platform" class="choice">
        <i
          class="fa"
          :class="[choice.platform === 'website' ? 'fa-globe' : 'fa-' + choice.platform, choice.platform]"
        />
        <input
          :value="
            links.find((link) => link.platform === choice.platform)
              ? links.find((link) => link.platform === choice.platform).link
              : ''
          "
          type="text"
          @input="socialLinks[choice.platform] = $event.target.value"
        />
      </div>
      <button class="save-button" @click="$emit('addsocial', socialLinks)">Übernehmen</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    choices: {
      type: Array,
      default: () => [],
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      socialLinks: {
        facebook: "",
        instagram: "",
        linkedin: "",
        xing: "",
        twitter: "",
        github: "",
      },
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  max-height: 60vh;
  margin: 0px auto;
  padding: 40px 60px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.choice {
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
  cursor: pointer;

  i {
    margin-right: 1rem;
    font-size: 2rem;
    width: 2rem;
  }

  input {
    background-color: transparent;
    border: medium solid #ddd;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 0.4rem;
    width: 20vw;

    &:focus {
      outline: none;
      border-color: $eh-green;
    }
  }
}
.save-button {
  margin-top: 2rem;
  border-radius: 10px;
  background-color: $eh-green;
  color: white;
  padding: 1rem 2rem;
  border: none;
  font-weight: bold;
  opacity: 0.9;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }
}

.facebook {
  color: #3b5998;
}

.twitter {
  color: #0084b4;
}

.github {
  color: #333;
}

.xing {
  color: #026466;
}

.instagram {
  color: #c13584;
}

.kununu {
  background-image: url("../../assets/kununu-logo.png");
  color: #2867b2;
  background-color: black;
  width: 30px;
  height: 30px;
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
