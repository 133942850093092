<template>
  <div class="modal-mask" @click="$emit('close')">
    <div class="modal-container" @click.stop>
      <div v-for="choice in choices" :key="choice.nameCode" class="choice" @click="$emit('addvalue', choice)">
        <img :src="choice.icon" />
        <p>{{ choice.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    choices: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  max-height: 50vh;
  margin: 0px auto;
  padding: 20px 60px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  overflow-x: auto;
}

.choice {
  display: flex;
  margin-bottom: 1.4rem;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;

  img {
    margin-right: 1rem;
    height: 2rem;
  }

  p {
    margin: 0;
  }
}
</style>
